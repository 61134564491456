const notfound = {
  pageName: {
    en: `404: Not Found`,
    th: `404 ไม่พบหน้านี้`,
  },
  title: {
    en: `Page Not Found`,
    th: `ไม่พบหน้านี้`,
  },
  description: {
    en: `Looks like you have followed a broken link or entered a URL that doesn't exist on this site.`,
    th: `ดูเหมือนว่าคุณได้เรียกลิงค์ที่เสียหายหรือ URL ที่ไม่มีอยู่จริงบนไซต์นี้`,
  },
  backButton: {
    en: `Go back to the homepage`,
    th: `กลับสู่หน้าหลัก`,
  },
}

export default notfound
