import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Button } from "@mui/material"
import { MdArrowBackIos } from "react-icons/md"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MainTitle from "../components/main-title"
import notfound from "../static/notfound"
import { setCurrentPage } from "../states/slices/main-slice"
import { playSound } from "../states/slices/sound-slice"

const NotFound = () => {
  const { lang } = useSelector(({ mainReducer }) => mainReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPage({ page: `notfound` }))
  }, [dispatch])

  return (
    <Layout>
      <Seo title={notfound.pageName[lang]} />
      <MainTitle title={notfound.title[lang]} align="left" />
      <p
        style={{
          margin: `0 0 1rem`,
        }}
      >
        {notfound.description[lang]}
      </p>
      <Button
        style={{
          transition: `0.3s`,
        }}
        aria-label="go-back-to-homepage"
        onClick={() => {
          navigate(`/`)
          dispatch(playSound(`confirm`))
        }}
        color="primary"
      >
        <MdArrowBackIos
          style={{
            fontSize: `1rem`,
            marginRight: 4,
          }}
        />
        <span
          style={{
            textTransform: `capitalize`,
          }}
        >
          {notfound.backButton[lang]}
        </span>
      </Button>
      <div
        style={{
          height: `3rem`,
        }}
      />
    </Layout>
  )
}

export default NotFound
